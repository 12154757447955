<template>
  <div class="content">
    <div class="banner">
      <div class="search">
        <input
          @keyup.enter="search"
          type="text"
          v-model.trim="value"
          placeholder="请输入报告编号（位于各文件标题左上角）"
        />
        <div class="btn" @click="search">查询</div>
      </div>
    </div>
    <div class="errs" v-if="!msg">
      <img src="../assets/search/err.png" alt />
      <div>没有找到对应的报告，核对下报告编号～</div>
    </div>
    <div class="box" v-if="msg">
      <div class="msg">
        <div class="m-t">报告内容</div>
        <ul class="info">
          <li>
            <div>报告编号</div>
            <div v-if="msg.number">{{msg.number}}</div>
            <div v-else>-</div>
          </li>
          <li>
            <div>车辆名称</div>
            <div v-if="msg.title">{{msg.title}}</div>
            <div v-else>-</div>
          </li>
          <li>
            <div>车辆识别代号</div>
            <div v-if="msg.vin">{{msg.vin}}</div>
            <div v-else>-</div>
          </li>
          <li>
            <div>号牌号码</div>
            <div v-if="msg.plateNo">{{msg.plateNo}}</div>
            <div v-else>-</div>
          </li>
          <li>
            <div>注册日期</div>
            <div v-if="msg.registerDate">{{msg.registerDate}}</div>
            <div v-else>-</div>
          </li>
          <li v-if="moreInfo">
            <div>评估基准日</div>
            <div v-if="msg.evaDate">{{msg.evaDate}}</div>
            <div v-else>-</div>
          </li>
          <li v-if="moreInfo">
            <div>车辆所在地</div>
            <div v-if="msg.location">{{msg.location}}</div>
            <div v-else>-</div>
          </li>
          <li v-if="moreInfo">
            <div>评估目的</div>
            <div v-if="msg.aim">{{msg.aim}}</div>
            <div v-else>-</div>
          </li>
          <li v-if="moreInfo">
            <div>评估师</div>
            <div v-if="msg.evaPersonName">{{msg.evaPersonName}}</div>
            <div v-else>-</div>
          </li>
          <li v-if="moreInfo">
            <div>评估机构</div>
            <div v-if="msg.evaOrg">{{msg.evaOrg}}</div>
            <div v-else>-</div>
          </li>
        </ul>
        <div class="more" @click="showInfo" v-if="!moreInfo">
          <div>更多信息</div>
          <van-icon name="arrow-down" color="#999999" />
        </div>
      </div>
      <div class="report">
        <div class="r-t">
          <div>报告文件</div>
          <div>点击预览报告</div>
        </div>
        <ul class="file">
          <li
            v-for="(item,index) in msg.evaReportList"
            :key="index"
            @click="reportUrl(item.reportUrl)"
          >
            <img src="../assets/search/file.png" alt />
            <div>{{item.dataCn}}.pdf</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getTaskDetailByNumber } from "@/utils/http.js";
import { debounce } from "@/utils/common.js";
import { Toast } from "vant";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      value: "",
      msg: "1",
      moreInfo: false,
      pdfh5: null,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.value = this.$store.state.reportNum;
    console.log(this.$store.state.reportNum);
    if (this.$store.state.reportNum) {
      this.search();
    } else {
      this.msg = "";
    }
  },
  beforeDestroy() {
    this.$store.commit("set_reportNum", "");
  },
  methods: {
    showInfo() {
      this.moreInfo = true;
    },
    search: debounce(async function () {
      if (!this.value) {
        Toast("请输入要查询的报告编号");
        this.msg = "";
        return;
      }
      if (this.value.length != 32) {
        Toast("请核对报告编号是否正确");
        this.msg = "";
        return;
      }
      const { data: res } = await getTaskDetailByNumber({
        number: this.value,
      });
      if (res.resultStates != 0) {
        this.msg = "";
        return;
      }
      this.msg = res.content;
    }, 500),
    reportUrl: debounce(function (data) {
      //   this.$store.commit("set_pdf", data);
      //   this.$router.push("/pdf");
      if (!data) return this.$message.error("报告暂时无法打开，请稍后再试");
      var a = document.createElement("a");
      a.href = data;
      a.target = "_blank";
      a.click();
    }, 500),
  },
};
</script>

<style  scoped>
.content {
  /* height: 100vh; */
  background: #f8f8f8;
}
.banner {
  width: 100%;
  background-image: url("../assets/search/ban.png");
  background-size: cover;
  height: 80px;
  box-sizing: border-box;
  padding-top: 15px;
}
.search {
  width: 335px;
  margin: 0 auto;
  height: 35px;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
}
.btn {
  width: 48px;
  line-height: 35px;
  background: #0502cf;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  font-size: 13px;
}
.search > input {
  border-radius: 4px 0px 0px 4px;
  height: 100%;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  flex: 1;
  box-sizing: border-box;
  padding-left: 10px;
}
.errs {
  width: 335px;
  height: 500px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: -15px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.errs > img {
  width: 140px;
  height: 122px;
  margin: 110px 0 20px 0;
}
.box {
  width: 335px;
  margin: 0 auto;
  margin-top: -15px;
  min-height: 100px;
  padding-bottom: 30px;
}
.msg {
  box-sizing: border-box;
  padding: 15px 10px;
  border-radius: 6px;
  background: #ffffff;
}
.m-t {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}
.info > li {
  padding: 8px 0;
  border-top: 1px solid #dbdbdb;
}
.info > li:nth-of-type(1) {
  border: none;
}
.info > li > div:nth-of-type(1) {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #666666;
}
.info > li > div:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 600;
  color: #333333;
  margin-top: 8px;
}
.report {
  box-sizing: border-box;
  padding: 15px 10px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
}
.r-t {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* margin-bottom: 10px; */
}
.r-t > div:nth-of-type(1) {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.file > li {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  height: 56px;
  border-radius: 2px;
  border: 1px solid #e2e2e2;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 10px;
}

.file > li > img {
  width: 34px;
  height: 40px;
  margin-right: 10px;
}
.file > li > div {
  width: 240px;
  line-height: 20px;
}
.more {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  justify-content: center;
}
.more > div:nth-of-type(1) {
  margin-right: 5px;
}
</style>